import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import InnerMenu from "components/headers/InnerMenu.js";
import DownloadApp from "../../components/cta/DownloadApp";
import ToolboneInnerFooter from "../../components/footers/ToolboneInnerFooter";
import imageSrc from "images/carousel-2.png";

const HighlightedTextInverse = tw.span`bg-gray-300 text-primary-700 px-4 transform -skew-x-12 inline-block`;

export default () => {
    return (
        <AnimationRevealPage>
            <InnerMenu/>
            <DownloadApp
                linkIOS={"https://apps.apple.com/app/id6737012387"}
                linkAndroid={"https://play.google.com/store/apps/details?id=com.toolbone.splodge"}
                linkWindows={"https://musesync.com"}
                text={<>Isn't just a digital colouring book<HighlightedTextInverse>Splodge</HighlightedTextInverse> app
                    it's a gateway to creativity, relaxation, and skill-building for all ages</>}
                imageSrc={imageSrc}
            />
            <ToolboneInnerFooter/>
        </AnimationRevealPage>
    );
};
