import React, {useEffect} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import mockupImageSrc from "images/carousel-3.png";
import {ReactComponent as SvgDecoratorBlob1} from "images/svg-decorator-blob-9.svg";
import {Container as ContainerBase, ContentWithPaddingXl} from "components/misc/Layouts";
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png";

// Change the container background from dark to light.
const Container = tw(ContainerBase)`-mx-8 my-16`;
const Content = tw(ContentWithPaddingXl)``;
const Row = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center`;

const ColumnContainer = tw.div`max-w-2xl`;
const TextContainer = tw(ColumnContainer)``;
// Change the text color from light to dark.
const Text = tw(SectionHeading)`text-gray-900 lg:text-left max-w-none text-3xl leading-snug`;
const Subheading = tw(SubheadingBase)`text-yellow-500 mb-4 tracking-wider`;

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`;

// Invert the button colors: now the buttons have a dark background with light text.
const Link = styled.a`
    ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-700 text-gray-800 hocus:text-gray-100 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
    img {
        ${tw`inline-block h-8 mr-3`}
    }

    span {
        ${tw`leading-none inline-block`}
    }
`;

const ImageContainer = tw(ColumnContainer)`mt-16 lg:mt-0 lg:ml-16 flex justify-end`;

// Adjust the decorator blobs to use a lighter tone so they complement the new light theme.
const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-gray-200 opacity-50`;
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform translate-x-20 -translate-y-64 text-gray-200 opacity-50`;

export default ({
                    subheading = "Download App",
                    text = "Developers all over the world are happily using Treact.",
                    link1Text = "App Store",
                    linkIOS: linkIOS = "http://apple.com",
                    link1IconSrc = appleIconImageSrc,
                    link2Text = "Google Play",
                    linkAndroid: linkAndroid = "http://play.google.com",
                    linkWindows: linkWindows = "",
                    link2IconSrc = googlePlayIconImageSrc,
                    pushDownFooter = false,
                    imageSrc = mockupImageSrc,
                }) => {
    // -------------------------------
    // PLATFORM DETECTION & REDIRECTION WITH 3s DELAY
    // -------------------------------
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let redirectUrl = "";

        // Detect iOS devices (iPhone, iPad, iPod)
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            redirectUrl = linkIOS; // Use App Store link for iOS
        }
        // Detect Android devices
        else if (/android/i.test(userAgent)) {
            redirectUrl = linkAndroid; // Use Google Play link for Android
        }
        // Detect Windows platform
        else if (/Win/.test(navigator.platform)) {
            redirectUrl = linkWindows; // Replace with your Windows link
        }
        // Detect Mac platform
        else if (/Mac/.test(navigator.platform)) {
            redirectUrl = linkIOS; // Use App Store link for iOS
        }

        if (redirectUrl) {
            // Add a 3 second delay before redirecting
            setTimeout(() => {
                window.location.href = redirectUrl;
            }, 25000);
        }
    }, [linkIOS, linkAndroid]);

    return (
        <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
            <Content>
                <Row>
                    <TextContainer>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Text>{text}</Text>
                        <LinksContainer>
                            <Link href={linkIOS}>
                                <img src={link1IconSrc} alt="App Store"/>
                                <span>{link1Text}</span>
                            </Link>
                            <Link href={linkAndroid}>
                                <img src={link2IconSrc} alt="Google Play"/>
                                <span>{link2Text}</span>
                            </Link>
                        </LinksContainer>
                    </TextContainer>
                    <ImageContainer>
                        <img src={imageSrc} alt="App Mockup" tw="w-128"/>
                    </ImageContainer>
                </Row>
                <DecoratorBlobContainer>
                    <DecoratorBlob1/>
                    <DecoratorBlob2/>
                </DecoratorBlobContainer>
            </Content>
        </Container>
    );
};
