import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import InnerMenu from "components/headers/InnerMenu.js";
import DownloadApp from "../../components/cta/DownloadApp";
import ToolboneInnerFooter from "../../components/footers/ToolboneInnerFooter";
import imageSrc from "images/carousel-1.png";

const HighlightedTextInverse = tw.span`bg-gray-300 text-primary-700 px-4 transform -skew-x-12 inline-block`;

export default () => {
    return (
        <AnimationRevealPage>
            <InnerMenu/>
            <DownloadApp
                linkIOS={"https://apps.apple.com/app/id6447834515"}
                linkAndroid={"https://play.google.com/store/apps/details?id=com.toolbone.musesync"}
                linkWindows={"https://musesync.com"}
                text={<>Music score or sheet viewer<HighlightedTextInverse>MuseNote</HighlightedTextInverse> App</>}
                imageSrc={imageSrc}
            />
            <ToolboneInnerFooter/>
        </AnimationRevealPage>
    );
};
