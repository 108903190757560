import React from "react";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ToolboneHome from "components/hero/ToolboneHome.js";
import ToolboneFeatures from "components/features/ToolboneFeatures.js";
import ToolboneAbout from "components/features/ToolboneAbout.js";
import ToolboneWorkflow from "components/features/ToolboneWorkflow"
import ToolbonePricing from "components/pricing/ToolbonePricing.js";
import ToolboneStories from "components/testimonials/ToolboneStories.js";
import ToolboneFAQs from "components/faqs/ToolboneFAQs.js";
import ToolboneFooter from "components/footers/ToolboneFooter.js";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import {ReactComponent as BriefcaseIcon} from "feather-icons/dist/icons/briefcase.svg";
import {ReactComponent as MoneyIcon} from "feather-icons/dist/icons/dollar-sign.svg";
import styled from "styled-components";
import Menu from "../components/headers/MainMenu";
import ShieldIconImage from "../images/shield-icon.svg";
import CustomizeIconImage from "../images/customize-icon.svg";
import ReliableIconImage from "../images/reliable-icon.svg";
import FastIconImage from "../images/fast-icon.svg";
import SimpleIconImage from "../images/simple-icon.svg";
import IntegrationIconImage from "../images/integration.svg";


const Section = styled.div(({bgColor}) => [
    tw`py-20 -mx-8 px-8 my-12`,
    bgColor && css`
        background-color: ${bgColor};
        opacity: 0.9;
    `
]);


export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;

    return (

        <AnimationRevealPage>
            <Menu roundedHeaderButton={true}/>

            <Section id="home" bgColor="rgba(240, 240, 240, 0.9)">
                <ToolboneHome/>
            </Section>

            <Section id="about">
                <ToolboneAbout
                    subheading={<Subheading>About</Subheading>}
                    heading={
                        <>
                            From Vision to <HighlightedText>Innovation</HighlightedText><br/>— Toolbone Story
                        </>
                    }
                    description={<> <span tw="font-bold text-primary-800">Toolbone</span> was founded on 2019 with a
                        mission to revolutionize app development by providing
                        scalable and efficient solutions. With years of experience in mobile and web technologies, we
                        created ArchiCore™, a powerful architecture designed to streamline development and enhance
                        performance. Our expertise in building high-quality applications has helped businesses turn
                        ideas into reality. At Toolbone, we don’t just build apps—we build the future of digital
                        experiences.</>}
                    info={<>Our portfolio includes cutting-edge mobile apps like <span
                        tw="font-bold italic">MuseSync</span> — an AI powered tool
                        for music performance evaluation and Learning, <span tw="font-bold italic">MuseTuner</span> — a
                        precise instrument tuning app, and
                        <span tw="font-bold italic"> Splodge</span> — an interactive AI-based learning platform. We also
                        developed <span tw="font-bold italic">MuseNote</span> — a real-time
                        collaborative music tool, and <span tw="font-bold italic">Trailer Pro</span> — a movie trailer
                        viewer that provides synopses,
                        ratings, release dates, and streaming availability to help users decide what to watch next. Each
                        app reflects our commitment to innovation, usability, and seamless functionality, all powered by
                        ArchiCore™ for exceptional performance and scalability.</>}
                    imageSrc={prototypeIllustrationImageSrc}
                    showDecoratorBlob={false}
                    features={[
                        {
                            Icon: MoneyIcon,
                            title: "Affordable",
                            description: "We promise to offer you the best rate we can - at par with the industry standard.",
                            iconContainerCss: tw`bg-green-300 text-green-800`
                        },
                        {
                            Icon: BriefcaseIcon,
                            title: "Professionalism",
                            description: "We assure you that our templates are designed and created by professional designers.",
                            iconContainerCss: tw`bg-red-300 text-red-800`
                        }
                    ]}
                />
            </Section>

            <Section id="features">
                <ToolboneFeatures
                    subheading={<Subheading>Features</Subheading>}
                    heading={
                        <>
                            We have Amazing <HighlightedText>Architecture.</HighlightedText>
                        </>
                    }
                    description={<>Our innovative architecture streamlines your app development, delivering high
                        performance, flexibility, and ease-of-use—all designed to help you succeed.</>}
                    cards={[
                        {
                            imageSrc: CustomizeIconImage,
                            title: "Customizable",
                            description: "Tailor every element to match your unique needs."
                        },
                        {
                            imageSrc: ReliableIconImage,
                            title: "Streamlined",
                            description: "Build faster with ArchiCore’s modular design."
                        },
                        {
                            imageSrc: IntegrationIconImage,
                            title: "Easy",
                            description: "Enjoy a straightforward, intuitive development process."
                        },
                        {
                            imageSrc: ShieldIconImage,
                            title: "Reliable",
                            description: "Count on robust and consistent functionality."
                        },
                        {
                            imageSrc: FastIconImage,
                            title: "Scalable",
                            description: "Easily grow with an adaptable architecture."
                        },
                        {
                            imageSrc: SimpleIconImage,
                            title: "AI-Powered",
                            description: "Enhance experiences with smart, integrated AI."
                        },
                    ]}
                />
            </Section>

            <Section id="pricing">
                <ToolbonePricing
                    subheading={<Subheading>Pricing</Subheading>}
                    description={<>
                        Toolbone offers flexible and reasonable pricing models tailored to different types of clients
                    </>}
                    heading={
                        <>
                            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
                        </>
                    }
                    plans={[
                        {
                            name: "Startup",
                            price: "$9,000",
                            duration: "One-Time",
                            mainFeature: "For Startups or Small",
                            features: ["Publish-ready app", "Supports iOS and Android", "5 Essential Screens", "Remote Config Integration", "Standard Themes Customization", "Basic Support & Docs"]
                        },
                        {
                            name: "Business",
                            price: "$12,000",
                            duration: "Monthly (3 Months Project Length)",
                            mainFeature: "For Mid-Size Businesses",
                            features: ["ArchiCore™ Source Code", "Publish-ready app", "Supports iOS and Android", "Supports Web(Based on Feature)", "Themes Customization", "Unlimited Screens"],
                            featured: true
                        },
                        {
                            name: "Enterprise",
                            price: "$120,000",
                            duration: "One-Time",
                            mainFeature: "For Large Companies",
                            features: ["White Label Toolbone App", "ArchiCore™ Source Code", "Complete Documentation"]
                        }
                    ]}
                />
            </Section>

            <Section id="workflow" bgColor="rgba(240, 240, 240, 0.9)">
                <ToolboneWorkflow
                    subheading="WORKFLOW"
                    description={<>At <span tw="font-bold text-primary-500">Toolbone</span>, we believe in delivering
                        high-quality solutions with a balance of
                        professionalism, affordability, and customer-focused service. Our approach ensures that every
                        client
                        gets the best possible product while maintaining transparency, efficiency, and innovation.</>}
                    features={[
                        {
                            Icon: BriefcaseIcon,
                            title: "Professionalism",
                            description: "We uphold industry-leading standards with a structured development process, clear communication, and a commitment to delivering high-quality, scalable applications on time.",
                            iconContainerCss: tw`bg-teal-300 text-teal-800`
                        },
                        {
                            Icon: MoneyIcon,
                            title: "Affordable",
                            description: "Our cost-effective solutions provide premium quality at competitive rates, with flexible pricing models and efficient development practices that maximize value without unnecessary costs.",
                            iconContainerCss: tw`bg-red-300 text-red-800`
                        }
                        // ...add more features as needed
                    ]}

                />
            </Section>

            <Section id="stories">
                <ToolboneStories
                    subheading={<Subheading>STORIES</Subheading>}
                    heading={
                        <>
                            Our Clients <HighlightedText>Love Us.</HighlightedText>
                        </>
                    }
                    description={<>At Toolbone, we take pride in building exceptional digital experiences, and our
                        clients' success is our greatest reward. From startups to enterprises, businesses trust us for
                        our expertise, innovation, and dedication to delivering high-quality solutions. See what they
                        have to say about working with us!</>}
                    testimonials={[
                        {
                            stars: 5,
                            profileImageSrc:
                                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                            heading: "Amazing User Experience!",
                            quote:
                                "ArchiCore delivers an amazing user experience with its seamless, high-performance architecture. Apps built on ArchiCore feel intuitive, responsive, and effortlessly scalable—setting a new standard in mobile and web development!",
                            customerName: "Charlotte Hale",
                            customerTitle: "Director, Delos Inc."
                        },
                        {
                            stars: 5,
                            profileImageSrc:
                                "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                            heading: "Seamless Performance & Scalability",
                            quote:
                                "With ArchiCore, performance and scalability are never a concern. It ensures smooth, lag-free experiences while effortlessly handling growth, making it the perfect foundation for high-demand mobile and web applications!",
                            customerName: "Adam Cuppy",
                            customerTitle: "Founder, EventsNYC"
                        }
                    ]}
                />
            </Section>

            <Section id="faqs">
                <ToolboneFAQs
                    subheading={<Subheading>FAQS</Subheading>}
                    description={<>Commonly Asked Questions, Answered</>}
                    heading={
                        <>
                            You have <HighlightedText>Questions ?</HighlightedText>
                        </>
                    }
                    faqs={[
                        {
                            question: "What is ArchiCore and how does it work?",
                            answer:
                                "ArchiCore is a powerful and scalable architecture designed for mobile and web app development. It provides a structured framework with pre-built components, seamless API integration, and optimized performance, allowing developers to build high-quality applications efficiently."
                        },
                        {
                            question: "Can I use ArchiCore for both mobile and web apps?",
                            answer:
                                "Yes! ArchiCore is designed to support both mobile (iOS & Android) and web applications, ensuring a seamless development experience across platforms while maintaining speed, security, and scalability."
                        },
                        {
                            question: "How does Toolbone ensure affordability without compromising quality?",
                            answer:
                                "We optimize development processes using ArchiCore’s modular architecture, reducing time and effort while maintaining high performance and security. Our flexible pricing models cater to different budgets, ensuring premium quality at cost-effective rates."
                        },
                        {
                            question: "Do you offer custom app development services?",
                            answer:
                                "Absolutely! Whether you need a completely custom-built app or want to integrate ArchiCore into your existing project, Toolbone offers end-to-end development services tailored to your business needs."
                        },
                        {
                            question: "How can I get started with ArchiCore or work with Toolbone?",
                            answer:
                                "Getting started is simple! Contact us today, and we’ll discuss your project, requirements, and the best approach to bring your app to life using ArchiCore’s cutting-edge architecture."
                        }
                    ]}
                />
            </Section>
            <ToolboneFooter/>
        </AnimationRevealPage>
    );
}
