import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import InnerMenu from "components/headers/InnerMenu.js";
import {SectionHeading} from "components/misc/Headings";
import ToolboneInnerFooter from "../components/footers/ToolboneInnerFooter";

const Container = tw.div`relative py-20`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
    ${tw`text-lg text-gray-800`}
    p {
        ${tw`mt-2 leading-loose`}
    }

    h1 {
        ${tw`text-3xl font-bold mt-10`}
    }

    h2 {
        ${tw`text-2xl font-bold mt-8`}
    }

    h3 {
        ${tw`text-xl font-bold mt-6`}
    }

    ul {
        ${tw`list-disc list-inside`}
        li {
            ${tw`ml-2 mb-3`}
            p {
                ${tw`mt-0 inline leading-normal`}
            }
        }
    }
`;

export default ({headingText = "Terms And Conditions"}) => {
    return (
        <AnimationRevealPage>
            <InnerMenu/>
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Text>
                        <p>Last updated: February 9, 2025</p>

                        <p>Please read these terms and conditions carefully before using Our Service.</p>

                        <h1>Interpretation and Definitions</h1>
                        <h2>Interpretation</h2>
                        <p>
                            The words of which the initial letter is capitalized have meanings defined under the
                            following conditions.
                        </p>
                        <p>
                            The following definitions shall have the same meaning regardless of whether they appear in
                            singular or in plural.
                        </p>

                        <h2>Definitions</h2>
                        <p>For the purposes of these Terms and Conditions:</p>
                        <ul>
                            <li>
                                <strong>Affiliate</strong> means an entity that controls, is controlled by or is under
                                common control
                                with a party, where "control" means ownership of 50% or more of the shares, equity
                                interest or other
                                securities entitled to vote for election of directors or other managing authority.
                            </li>
                            <li>
                                <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in
                                this Agreement)
                                refers to Toolbone LLP.
                            </li>
                            <li>
                                <strong>Country</strong> refers to: Singapore.
                            </li>
                            <li>
                                <strong>Service</strong> refers to the Website.
                            </li>
                            <li>
                                <strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and
                                Conditions that
                                form the entire agreement between You and the Company regarding the use of the Service.
                            </li>
                            <li>
                                <strong>Third-party Social Media Service</strong> means any services or content
                                (including data,
                                information, products or services) provided by a third-party that may be displayed,
                                included or made
                                available by the Service.
                            </li>
                            <li>
                                <strong>Website</strong> refers to Toolbone, accessible from https://toolbone.com
                            </li>
                            <li>
                                <strong>You</strong> means the individual accessing or using the Service, or the
                                company, or other legal
                                entity on behalf of which such individual is accessing or using the Service, as
                                applicable.
                            </li>
                        </ul>

                        <h1>Acknowledgement</h1>
                        <p>
                            These are the Terms and Conditions governing the use of this Service and the agreement that
                            operates
                            between You and Toolbone. These Terms and Conditions set out the rights and obligations of
                            all users
                            regarding the use of the Service.
                        </p>
                        <p>
                            By accessing or using the Service You agree to be bound by these Terms and Conditions. If
                            You disagree
                            with any part of these Terms and Conditions then You may not access the Service.
                        </p>
                        <p>
                            Your access to and use of the Service is also conditioned on Your acceptance of and
                            compliance with the
                            Privacy Policy of Toolbone. Our Privacy Policy describes Our policies and procedures on the
                            collection,
                            use, and disclosure of Your personal information when You use the Website and tells You
                            about Your privacy
                            rights and how the law protects You. Please read Our Privacy Policy carefully before using
                            Our Service.
                        </p>

                        <h1>Links to Other Websites</h1>
                        <p>
                            Our Service may contain links to third-party web sites or services that are not owned or
                            controlled by Toolbone.
                        </p>
                        <p>
                            Toolbone has no control over, and assumes no responsibility for, the content, privacy
                            policies, or
                            practices of any third party web sites or services. You further acknowledge and agree that
                            Toolbone
                            shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
                            alleged to be
                            caused by or in connection with the use of or reliance on any such content, goods, or
                            services available on
                            or through any such web sites or services.
                        </p>
                        <p>
                            We strongly advise You to read the terms and conditions and privacy policies of any
                            third-party web sites
                            or services that You visit.
                        </p>

                        <h1>Contact Us</h1>
                        <p>If you have any questions about these Terms and Conditions, You can contact us:</p>

                        <ul>
                            <li>By email: support@toolbone.com</li>
                        </ul>
                        <ul>
                            <li>By phone: +65 9337 9630</li>
                        </ul>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <ToolboneInnerFooter/>
        </AnimationRevealPage>
    );
};
