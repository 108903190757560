import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    Autoplay,
    EffectCreative,
    Pagination
} from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

import CarouselImage1 from "../../images/carousel-1.png";
import CarouselImage2 from "../../images/carousel-2.png";
import CarouselImage3 from "../../images/carousel-3.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row items-start justify-between max-w-screen-xl mx-auto pb-4 py-0`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 py-0`;
const LeftColumn = tw(Column)`md:w-7/12 flex-shrink-0 relative py-0`;
const RightColumn = tw(Column)`md:w-8/12 mt-8 md:mt-0 py-0`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`mb-0 text-base xl:text-lg`;

const BottomTextContainer = tw.div`mt-6 md:mt-8 text-center md:text-left md:w-full max-w-screen-xl mx-auto`;
const BottomText = tw.div`mb-0 text-base xl:text-lg`;
const BottomText1 = tw.div`mb-0 text-base xl:text-lg text-center md:text-left text-lg md:text-xl text-gray-700 md:w-7/12`;

const TextContainer = tw.div`lg:py-6 text-center md:text-left`;
// Swiper Container Styling
const CarouselContainer = styled.div`
    ${tw`w-full max-w-lg xl:max-w-3xl flex justify-center`}
    .swiper {
        padding: 8px;
        width: 100%;
        height: 100%;
    }
    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-pagination-bullet {
        background: #3914cf !important;
        border-radius: 20px;
    }
    .swiper-pagination-bullet-active {
        background: #3914cf !important;
        width: 28px;
        border-radius: 20px;
    }
`;

const DecoratorBlob = styled(SvgDotPattern)`
    ${tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`}
`;

export default ({
                    subheading = "Our Track Record",
                    heading = (
                        <>
                            We have been doing this <wbr /> since <span tw="text-primary-500">1999.</span>
                        </>
                    ),
                    description = "Description",
                    info = "Info",
                    textOnLeft = false
                }) => {
    return (
        <Container>
            <TwoColumn>
                <LeftColumn>
                    <CarouselContainer>
                        <Swiper
                            loop
                            autoplay={{ delay: 5000, disableOnInteraction: false }}
                            grabCursor={true}
                            pagination={{ clickable: true, dynamicBullets: false }}
                            navigation={false}
                            effect={'creative'}
                            creativeEffect={{
                                prev: { shadow: false, scale: 0, translate: [360, 0, 0] },
                                next: { shadow: false, translate: [-360, 0, 0], scale: 0 }
                            }}
                            modules={[EffectCreative, Pagination, Autoplay]}
                        >
                            <SwiperSlide>
                                <a href="https://toolbone.com/ads/musetuner" target="_blank" rel="noopener noreferrer">
                                    <img src={CarouselImage1} alt="MuseTuner" tw="w-full rounded-lg " />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="https://toolbone.com/ads/splodge" target="_blank" rel="noopener noreferrer">
                                    <img src={CarouselImage2} alt="Splodge" tw="w-full rounded-lg " />
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href="https://toolbone.com/ads/musesync" target="_blank" rel="noopener noreferrer">
                                    <img src={CarouselImage3} alt="MuseSync" tw="w-full rounded-lg " />
                                </a>
                            </SwiperSlide>
                        </Swiper>
                    </CarouselContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        <Paragraph>{description}</Paragraph>
                    </TextContainer>
                </RightColumn>
            </TwoColumn>
            <BottomTextContainer>
                <BottomText>{info}</BottomText>
            </BottomTextContainer>
            <DecoratorBlob />
        </Container>
    );
};
