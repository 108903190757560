import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import {ReactTyped} from "react-typed";
import {ReactComponent as SvgDecoratorBlob1} from "../../images/svg-decorator-blob-1.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

import DesignIllustration from "../../images/software-illustration-1.svg";

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

const Container = tw.div`relative`;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-0 md:py-0`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-start`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;

const AnimatedHeading = () => (
    <Heading>
        Create Faster with <span style={{color: '#6415ff'}}>ArchiCore™</span>,<br/>Build Apps
        that are <br/>
        <ReactTyped
            strings={["Powerful", "Intelligent", "Innovative", "Scalable", "Reliable", "AI-Powered"]}
            typeSpeed={100}
            backSpeed={30}
            loop
            showCursor={true}
        />
    </Heading>
);

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
    ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
    input {
        ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500`}
    }

    button {
        ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
    }
`;

const CustomersLogoStrip = styled.div`
    ${tw`mt-12 lg:mt-20`}
    p {
        ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
    }

    img {
        ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
    }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
    ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

export default () => {
    const navigate = useNavigate();
    const subject = "For Business"
    const handleClick = () => {
        if (email) {
            navigate(`/contact-us?email=${encodeURIComponent(email)}&subject=${subject}`);
        }
    };
    const [email, setEmail] = useState("");


    return (
        <>
            <Container>
                <TwoColumn>
                    <LeftColumn>
                        <AnimatedHeading/>
                        <Paragraph>
                            ArchiCore™ by Toolbone is a powerful, flexible architecture designed for seamless mobile and
                            web app development. Let's accelerate your success.
                        </Paragraph>
                        <Actions>
                            <input
                                type="text"
                                placeholder="Your E-mail Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button onClick={handleClick} disabled={!email}>
                                Get Started
                            </button>
                        </Actions>
                        <CustomersLogoStrip>
                            <p>Our TRUSTED Customers & Sponsors</p>
                            <img src={CustomersLogoStripImage} alt="Our Customers"/>
                        </CustomersLogoStrip>
                    </LeftColumn>

                    {/* ✅ Swiper Carousel Here */}

                    <RightColumn>
                        <IllustrationContainer>
                            <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration}
                                 alt="Design Illustration"/>
                        </IllustrationContainer>
                    </RightColumn>
                </TwoColumn>
                <DecoratorBlob1/>
            </Container>
        </>
    );
};
