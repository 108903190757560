import {createGlobalStyle} from 'styled-components'
import {globalStyles} from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Enable smooth scrolling */

  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* ✅ Prevent horizontal scroll */
    scroll-behavior: smooth;
    width: 100vw; /* ✅ Ensure it never exceeds viewport width */

  }

   /* Below animations are for modal created using React-Modal */
    .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
`)

export default GlobalStyles