import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useNavigate } from "react-router-dom";

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";


const Header = styled.header`
    ${tw`fixed -mx-8 -my-8 w-screen z-50 flex justify-between items-center px-6 lg:px-12 py-4`};
    background: rgba(255, 255, 255, 0.9);
`;
export const NavLinks = tw.div`inline-block`;

export const NavLink = styled.a`
    ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`}
    ${({ active }) => active && tw`border-primary-500 text-primary-500`}
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
    ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
    img {
        ${tw`w-10 mr-3`}
    }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
    ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
    ${NavLinks} {
        ${tw`flex flex-col items-center`}
    }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeLink, setActiveLink] = useState(() => {
        const hash = window.location.hash.replace("#", "");
        return hash && document.getElementById(hash) ? hash : "home";
    });

    const navigate = useNavigate();
    const handleContactClick = (planName) => {
        navigate(`/contact-us?subject=${encodeURIComponent(planName)}`);
    };

    useEffect(() => {
        let lastUpdatedSection = "";

        const handleScroll = () => {
            const sections = ["home", "about", "features", "pricing", "workflow", "stories", "faqs"];
            let foundSection = "home"; // Default section

            for (let section of sections) {
                const element = document.getElementById(section);
                if (element) {
                    const sectionTop = element.offsetTop;
                    const sectionHeight = element.clientHeight;
                    if (window.scrollY >= sectionTop - sectionHeight / 3 && window.scrollY < sectionTop + sectionHeight / 3) {
                        foundSection = section;
                        break;
                    }
                }
            }

            // ✅ Only update state & URL if the section has changed
            if (foundSection !== lastUpdatedSection) {
                lastUpdatedSection = foundSection;
                setActiveLink(foundSection);
                window.history.replaceState(null, "", `#${foundSection}`);
            }
        };

        const handleHashChange = () => {
            const newHash = window.location.hash.replace("#", "");
            if (newHash) {
                const targetElement = document.getElementById(newHash);
                if (targetElement) {
                    window.scrollTo({ top: targetElement.offsetTop, behavior: "smooth" });
                    setActiveLink(newHash);
                }
            }
        };

        // Scroll to correct section on initial load if there's a hash
        if (window.location.hash) {
            handleHashChange();
        }

        // ✅ Attach event listeners
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    const scrollToSection = (e, id, closeMenu) => {
        if (e) e.preventDefault(); // Keep preventDefault but ensure URL updates

        const targetElement = document.getElementById(id);
        if (targetElement) {
            const y = targetElement.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ left: 0, top: y, behavior: "smooth" });
        }

        // ✅ Update URL & Active Link
        window.history.pushState(null, "", `#${id}`);

        // ✅ Ensure state updates on click
        setActiveLink(id);

        if (closeMenu) closeMenu();
    };

    const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();

    const defaultLinks = [
        <NavLinks key={1}>
            <NavLink href="#home" active={activeLink === "home"} onClick={(e) => scrollToSection(e, "home", toggleNavbar)}>
                Home
            </NavLink>
            <NavLink href="#about" active={activeLink === "about"} onClick={(e) => scrollToSection(e, "about", toggleNavbar)}>
                About
            </NavLink>
            <NavLink href="#features" active={activeLink === "features"} onClick={(e) => scrollToSection(e, "features", toggleNavbar)}>
                Features
            </NavLink>
            <NavLink href="#pricing" active={activeLink === "pricing"} onClick={(e) => scrollToSection(e, "pricing", toggleNavbar)}>
                Pricing
            </NavLink>
            <NavLink href="#workflow" active={activeLink === "workflow"} onClick={(e) => scrollToSection(e, "workflow", toggleNavbar)}>
                Workflow
            </NavLink>
            <NavLink href="#stories" active={activeLink === "stories"} onClick={(e) => scrollToSection(e, "stories", toggleNavbar)}>
                Stories
            </NavLink>
            <NavLink href="#faqs" active={activeLink === "faqs"} onClick={(e) => scrollToSection(e, "faqs", toggleNavbar)}>
                FAQs
            </NavLink>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} onClick={() => handleContactClick("Inquiry")} >
                Contact Us
            </PrimaryLink>
        </NavLinks>
    ];

    const collapseBreakPointCssMap = {
        sm: {
            mobileNavLinks: tw`sm:hidden`,
            desktopNavLinks: tw`sm:flex`,
            mobileNavLinksContainer: tw`sm:hidden`
        },
        md: {
            mobileNavLinks: tw`md:hidden`,
            desktopNavLinks: tw`md:flex`,
            mobileNavLinksContainer: tw`md:hidden`
        },
        lg: {
            mobileNavLinks: tw`lg:hidden`,
            desktopNavLinks: tw`lg:flex`,
            mobileNavLinksContainer: tw`lg:hidden`
        },
        xl: {
            mobileNavLinks: tw`xl:hidden`,
            desktopNavLinks: tw`xl:flex`,
            mobileNavLinksContainer: tw`xl:hidden`
        }
    };

    const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

    const defaultLogoLink = (
        <LogoLink href="/" style={{gap: "0px"}}>
            <img src={logo} alt="logo" style={{marginRight: "-4px"}}/>
            <span style={{color: "#000000", marginTop: "8px"}}>oolb</span>
            <span style={{color: "#3E6BE4", marginTop: "8px"}}>one</span>
        </LogoLink>
    );

    logoLink = logoLink || defaultLogoLink;
    links = links || defaultLinks;

    return (
        <Header className={className || "header-light"}>
            <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                {logoLink}
                {links}
            </DesktopNavLinks>

            <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
                {logoLink}
                <MobileNavLinks initial={{x: "150%", display: "none"}} animate={animation}
                                css={collapseBreakpointCss.mobileNavLinks}>
                    {links}
                </MobileNavLinks>
                <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
                    {showNavLinks ? <CloseIcon tw="w-6 h-6"/> : <MenuIcon tw="w-6 h-6"/>}
                </NavToggle>
            </MobileNavLinksContainer>
        </Header>
    );
};
