import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import {ReactComponent as FacebookIcon} from "../../images/facebook-icon.svg";
import {ReactComponent as TwitterIcon} from "../../images/twitter-icon.svg";
import {ReactComponent as YoutubeIcon} from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`relative flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`absolute w-12 left-0 transform translate-x-2 z-10 -translate-y-1`;
const LogoText = tw.h5`relative text-2xl font-black tracking-wider ml-8`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
    ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
    svg {
        ${tw`w-4 h-4`}
    }
`;

export default () => {
    return (
        <Container>
            <FiveColumns>
                <WideColumn>
                    <LogoContainer>
                        <LogoImg src={LogoImage}/>
                        <LogoText>Toolbone</LogoText>
                    </LogoContainer>
                    <CompanyDescription>
                        Toolbone - We have the tools of today<br/>a digital technology company founded in 2019 with a
                        mission to revolutionize application development.
                    </CompanyDescription>
                    <SocialLinksContainer>
                        <SocialLink href="https://facebook.com">
                            <FacebookIcon/>
                        </SocialLink>
                        <SocialLink href="https://twitter.com">
                            <TwitterIcon/>
                        </SocialLink>
                        <SocialLink href="https://youtube.com">
                            <YoutubeIcon/>
                        </SocialLink>
                    </SocialLinksContainer>
                </WideColumn>
                <Column>
                    <ColumnHeading>Quick Links</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="#home">Home</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="#about">About</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="#features">Features</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/contact-us?subject=Inquiry">Contact</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
                <Column>
                    <ColumnHeading>Products</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/ads/splodge">Splodge</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/ads/musesync">MuseSync</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/ads/musetuner">MuseTuner</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/ads/trailerpro">Trailer Pro</Link>
                        </LinkListItem>

                    </LinkList>
                </Column>
                <Column>
                    <ColumnHeading>Legal</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/privacy-policy">Privacy Policy</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/terms-of-service">Terms of Service</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
            </FiveColumns>
        </Container>
    );
};
