import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/InnerMenu.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import ToolboneInnerFooter from "../components/footers/ToolboneInnerFooter";
import {useLocation} from "react-router-dom";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
    const location = useLocation();

    // Function to get query parameters
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param) || "";
    };

    const email = getQueryParam("email"); // Get email from URL
    const subject = getQueryParam("subject"); // Get subject from URL

    return (
        <AnimationRevealPage>
            <Header/>
            <ContactUsForm email={email} subject={subject}/>
            <ContactDetails
                description={<>Discover our hubs — where innovation meets local expertise.</>}
                cards={[
                    {
                        title: "Singapore",
                        description: (
                            <>
                                <Address>
                                    <AddressLine>225 Serangoon Avenue 4</AddressLine>
                                    <AddressLine>Boundary Ville, Singapore 550225</AddressLine>
                                </Address>
                                <Email>support@toolbone.com</Email>
                                <Phone>+65 9337 9630</Phone>
                            </>
                        )
                    },
                    {
                        title: "Philippines",
                        description: (
                            <>
                                <Address>
                                    <AddressLine>P9W3+562, Lapu-Lapu Ext</AddressLine>
                                    <AddressLine>Digos City, Davao del Sur</AddressLine>
                                </Address>
                                <Email>support@toolbone.com</Email>
                                <Phone>+65 9337 9630</Phone>
                            </>
                        )
                    }
                ]}
            />
            <ToolboneInnerFooter/>
        </AnimationRevealPage>
    );
};
