import React, { useState } from "react";
import { useNavigate } from "react-router-dom";  // ✅ Import useNavigate
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
    ${tw`h-24`}
`;
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

// ✅ GOOGLE FORM SETUP
const GOOGLE_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSf0VO__vvAwfU8Tk-zcXXdB3qbAJ2jhv8E4lzNLNFuhP2nDoQ/formResponse";
const FIELD_ENTRY_IDS = {
    email: "entry.1285693041",
    name: "entry.1790673455",
    subject: "entry.1436305424",
    message: "entry.755689379",
};

export default ({
                    subheading = "Contact Us",
                    heading = <>Ready to <span tw="text-primary-500">get in touch?</span></>,
                    description = "Your next step to innovation starts here—our team is ready to guide you.",
                    submitButtonText = "Send",
                    textOnLeft = true,
                    email = "",
                    subject = "Inquiry"
                }) => {
    const navigate = useNavigate();  // ✅ Initialize useNavigate
    const [emailValue, setEmailValue] = useState(email);
    const [subjectValue, setSubjectValue] = useState(subject);
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new URLSearchParams();
        formData.append(FIELD_ENTRY_IDS.email, emailValue);
        formData.append(FIELD_ENTRY_IDS.name, name);
        formData.append(FIELD_ENTRY_IDS.subject, subjectValue);
        formData.append(FIELD_ENTRY_IDS.message, message);

        fetch(GOOGLE_FORM_URL, {
            method: "POST",
            mode: "no-cors",
            body: formData,
        })
            .then(() => {
                alert("Your message has been sent successfully!");
                navigate("/");  // ✅ Redirect to home after clicking "OK"
            })
            .catch(error => console.error("Error submitting form:", error));
    };

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        {subheading && <Subheading>{subheading}</Subheading>}
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                        <Form onSubmit={handleSubmit}>
                            <Input
                                type="email"
                                name="email"
                                placeholder="Your Email Address"
                                value={emailValue}
                                onChange={(e) => setEmailValue(e.target.value)}
                                required
                            />
                            <Input
                                type="text"
                                name="name"
                                placeholder="Your Name or Company"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <Input
                                type="text"
                                name="subject"
                                placeholder="Subject"
                                value={subjectValue}
                                onChange={(e) => setSubjectValue(e.target.value)}
                                disabled={subjectValue.trim() !== ""} // Disable if subject is pre-filled
                                required
                            />
                            <Textarea
                                name="message"
                                placeholder="Your Message Here"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                            <SubmitButton type="submit">{submitButtonText}</SubmitButton>
                        </Form>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
