import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`relative flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`absolute w-12 left-0 transform translate-x-2 z-10 -translate-y-1`;
const LogoText = tw.h5`relative text-2xl font-black tracking-wider ml-8`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

export default () => {
  return (
      <Container>
        <Content>
          <Row>
            <LogoContainer>
              <LogoImg src={logo} />
              <LogoText>Toolbone</LogoText>
            </LogoContainer>

            <LinksContainer>
              <Link href="/#home">Home</Link>
              <Link href="/privacy-policy">Privacy</Link>
              <Link  href="/contact-us?subject=Inquiry">Contact</Link>
              <Link href="/terms-of-service">Terms</Link>

            </LinksContainer>
            <SocialLinksContainer>
              <SocialLink href="https://facebook.com">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://twitter.com">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://youtube.com">
                <YoutubeIcon />
              </SocialLink>
            </SocialLinksContainer>
            <CopyrightText>
              Copyright &copy; {new Date().getFullYear()}, Toolbone. All Rights Reserved.
            </CopyrightText>
          </Row>
        </Content>
      </Container>
  );
};
